<!--
 * @Description: 房间显示
 * @Date: 2022-03-16 17:40:28
 * @LastEditTime: 2022-03-29 16:13:06
-->
<template>
  <div style="height: 100%">
    <div class="rtc-container">
      <!-- 进房操作区域 -->
      <!-- <p v-if="isHostMode" class="label">{{ $t('Operation') }}</p>
    <div class="control-container">
      <div class="rtc-control-container">
        <el-button
          class="button"
          type="primary"
          size="small" :disabled="isJoining || isJoined" @click="handleJoinRoom">{{ $t('Join Room') }}</el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          type="primary"
          size="small" :disabled="isPublishing || isPublished" @click="handlePublish">{{ $t('Publish') }}</el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          type="primary" size="small" @click="handleUnpublish">{{ $t('Unpublish') }}</el-button>
        <el-button
          class="button"
          type="primary" size="small" @click="handleLeave">{{ $t('Leave Room') }}</el-button>
      </div>
      <div v-if="isHostMode" class="screen-share-control-container">
        <el-button
          class="button"
          type="primary"
          size="small"
          :disabled="isShareJoined && isSharePublished"
          @click="handleStartScreenShare">{{ $t('Start Screen Share') }}</el-button>
        <el-button
          class="button"
          type="primary" size="small" @click="handleStopScreenShare">{{ $t('Stop Screen Share') }}</el-button>
      </div>
    </div> -->

      <!-- 显示邀请链接 -->
      <!-- <div v-if="showInviteLink" class="invite-link-container">
      <span v-if="isEnLang">Copy the link to invite friends to join the video call, one link can invite only one person,
        the link will be updated automatically after copying.</span>
      <span v-else>复制链接邀请好友加入视频通话，一条链接仅可邀请一人，复制后自动更新链接。</span>
      <el-input class="invite-input" v-model="inviteLink">
        <template slot="prepend">
          <el-tooltip
            :visibleArrow="false"
            effect="dark"
            content="Copied!"
            placement="bottom"
            :manual="true"
            v-model="showCopiedTip">
            <span class="invite-btn" @click="handleCopyInviteLink">
              <svg-icon icon-name="copy"></svg-icon>
            </span>
          </el-tooltip>
        </template>
</el-input>
</div> -->

      <!-- 远端流区域 -->
      <div class="remote-container">
        <div v-if="remoteStreamList.length < 1 && videoMediationMode === '20' && onVideoing" style="background-color: black;width: 100%;height: 100%;">
          <img
            style="height: auto;width: 70%;margin: 20% auto 0;display: block;"
            :src="remoteLoadingImgPath"
          />
          <div v-if="remoteStreamList.length < 1 && videoMediationMode === '20' && onVideoing" style="text-align: center;color: #ffffff;font-size: 14px;width: 100%;">Ai调解员加入中，请等待...</div>
        </div>  
        <div
          v-for="item in remoteStreamList"
          :key="item.getUserId()"
          :id="item.getUserId()"
          class="remote-stream-container"
        ></div>
      </div>

      <div
        class="info-container"
        :class="$isMobile && 'info-container-mobile'"
      >
        <!-- Log 展示区域 -->
        <!-- <div v-if="isHostMode" class="log-container" ref="logContainer">
        <p class="log-label">Log:</p>
        <div v-for="(item, index) in logList" :key="index">
          <span class="log-state" v-if="item.type === 'success'">🟩 </span>
          <span class="log-state" v-if="item.type === 'failed'">🟥 </span>
          <span>{{ item.log }}</span>
        </div>
      </div> -->

        <!-- 本地流区域 -->
        <div
          v-if="localStream"
          class="local-stream-container"
        >
          <!-- 本地流播放区域 -->
          <div
            id="localStream"
            class="local-stream-content"
          ></div>
          <!-- 本地流操作栏 -->
          <div
            v-if="isPlayingLocalStream"
            class="local-stream-control"
          >
            <div class="video-control control">
              <span
                v-if="!isMutedVideo"
                @click="muteVideo"
              >
                <svg-icon
                  icon-name="video"
                  class="icon-class"
                ></svg-icon>
              </span>
              <span
                v-if="isMutedVideo"
                @click="unmuteVideo"
              >
                <svg-icon
                  icon-name="video-muted"
                  class="icon-class"
                ></svg-icon>
              </span>
            </div>
            <div class="audio-control control">
              <span
                v-if="!isMutedAudio"
                @click="muteAudio"
              >
                <svg-icon
                  icon-name="audio"
                  class="icon-class"
                ></svg-icon>
              </span>
              <span
                v-if="isMutedAudio"
                @click="unmuteAudio"
              >
                <svg-icon
                  icon-name="audio-muted"
                  class="icon-class"
                ></svg-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="leaveBtn">
      <el-button
        type="primary"
        @click="handleLeave"
        >{{ $t('结束调解') }}
      </el-button>
    </div>
  </div>
</template>
<script src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import rtc from './mixins/rtc.js'
import shareRtc from './mixins/share-rtc.js'
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js'
import {
  getAICreatesessionAPI,
  getAIGetstateAPI,
  getAIStartsessionAPI,
  getAIClosesessionAPI,
  getAISignAPI,
  getAIAsrTextAPI,
  getTRTCAppidAPI,
  getTRTCAsrAPI
} from '@/api/videoAI'
import { startMCUMixTranscodeAPI, stopMCUMixTranscodeAPI, startMCUAiMixTranscodeAPI, getVideoUserListAPI } from '@/api/videoMediate'
import './asr.js'
export default {
  name: 'compRoom',
  mixins: [rtc, shareRtc],
  props: {
    type: String,
    // sdkAppId: Number,
    // secretKey: String,
    userId: String,
    roomId: Number,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
    videoMediationMode: String,
  },
  data() {
    return {
      sdkAppId: 1600045285,
      secretKey:
        '54c141a2915ea34452564e243a271bc6fa6b493b3e3439b6ef5fb1371ad2ae82',
      objectParams: {},
      // userId: '',
      // roomId: 0,
      // userId: "170019",
      // roomId: 1477966700,
      logList: [],
      inviteLink: '',
      showCopiedTip: false,
      sessionId: '',
      wssParams: null,
      wssUrl:
        'wss:gw.tvs.qq.com/v2/ws/ivh/interactdriver/interactdriverservice/commandchannel?',
      wsk: null,
      executeAction: null,
      wskCanSendMsg: true,
      talkCanBreak: false, // 是否允许打断
      uuid: null,
      webAudioSpeechRecognizer: null,
      isCanStop: false,
      remoteLoadingImgPath: require("@/assets/image/aiMediateWating.gif"),
      onVideoing: false,
      remoteUserList: [],
    }
  },
  computed: {
    isHostMode() {
      return this.type !== 'invite'
    },
    isEnLang() {
      return this.$i18n.locale === 'en'
    },
    showInviteLink() {
      return (
        this.isHostMode &&
        (this.isJoined || this.isShareJoined) &&
        this.inviteLink
      )
    },
    theStreamListLength() {
      return this.remoteStreamList.length
    }
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val)
    },
    microphoneId(val) {
      this.switchDevice('audio', val)
    },
    theStreamListLength(val) {
      console.warn('远端数量变化',val);
    }
    // $route: {
    //   handler(val) {
    //     this.userId = val.query.userId;
    //     this.roomId = val.query.roomId;
    //   },
    //   deep: true,
    //   immediate: true,
    // }
  },
  mounted() {
    // this.handleJoinRoom()
    // this.getRequest()
  },
  methods: {
    test() {
      console.log(this)
    },
    getRequest() {
      
      let url = decodeURI(window.location.search || window.location.hash)
      // console.log(url, "url");
      let strs
      if (url.indexOf('?') != -1) {
        // hash模式进此判断
        if (window.location.hash) {
          strs = url.split('?')[1].toString().split('&')
          // history模式
        } else {
          strs = url.substr(1).split('&')
        }
        // 循环遍历并添加到对象中
        for (let i = 0; i < strs.length; i++) {
          this.objectParams[strs[i].split('=')[0]] = strs[i].split('=')[1]
        }
      }
      console.log('this.objectParams',this.objectParams);
      return
      // 判断objectParams对象的长度，大于1则有值
      if (Object.keys(this.objectParams).length > 0) {
        this.userId = String(this.objectParams.userId)
        this.roomId = Number(this.objectParams.roomId)
        this.handleJoinRoom()
        // console.log(this.objectParams);
      }
    },
    generateInviteLink() {
      if (!this.isHostMode) {
        return
      }
      const { sdkAppId, secretKey, roomId, userId } = this
      const inviteUserId = userId.toString()
      const userSigGenerator = new LibGenerateTestUserSig(
        sdkAppId,
        secretKey,
        604800
      )

      const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId)

      this.inviteLink = encodeURI(
        `${location.origin}${location.pathname}#/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`
      )
    },
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink)
      this.showCopiedTip = true
      setTimeout(() => {
        this.showCopiedTip = false
      }, 1500)
      this.generateInviteLink()
    },
    // 点击【Join Room】按钮
    async handleJoinRoom() {
      
      console.log('userId',this.userId);
      console.log('roomId',this.roomId);
      this.remoteUserList = (await getVideoUserListAPI({roomId: this.roomId})).data
      // let arms = {
      //   roomId: this.roomId,
      // }

      // getAICreatesessionAPI(arms).then((res) => {
      //   console.log('获取sessionId', res)
      // })
      if (this.isHostMode) {
        if (!this.sdkAppId || !this.secretKey) {
          // alert(this.$t('Please enter sdkAppId and secretKey'));
          return
        }
        if (!this.userId || !this.roomId) {
          // alert(this.$t('Please enter userId and roomId'));
          return
        }
        const userSigGenerator = new LibGenerateTestUserSig(
          this.sdkAppId,
          this.secretKey,
          604800
        )
        this.userSig = userSigGenerator.genTestUserSig(this.userId)
      } else {
        if (
          !this.sdkAppId ||
          !this.inviteUserSig ||
          !this.userId ||
          !this.roomId
        ) {
          // alert(this.$t('Please reacquire the invitation link'));
          return
        }
        this.userSig = this.inviteUserSig
      }
      await this.initClient()
      await this.join()
      await this.initLocalStream()
      await this.playLocalStream()
      this.onVideoing = true
      await this.publish()
      this.generateInviteLink()
      if (this.videoMediationMode == '20') {
        await this.openMCUMix()
        this.uuid = this.getUUID()
        await this.createTalk()
      }
    },

    // 点击【Publish】按钮
    async handlePublish() {
      await this.publish()
    },

    // 点击【Unpublish】按钮
    async handleUnpublish() {
      await this.unPublish()
    },

    // 点击【Leave Room】按钮
    async handleLeave() {
      await this.leave()
      this.onVideoing = false
      if (this.videoMediationMode == '20') {
        // try {
        // } catch{}
        this.closeASR()
        this.closeTalk()
        this.closeWss()
        this.closeMCUix()
        
      }

      wx.miniProgram.navigateBack(-1)
    },

    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.secretKey) {
        alert(this.$t('Please enter sdkAppId and secretKey'))
        return
      }
      await this.initShareClient()
      await this.initShareLocalStream()
      await this.handleShareJoin()
      await this.handleSharePublish()
      this.generateInviteLink()
    },

    // 点击【停止屏幕分享按钮】
    async handleStopScreenShare() {
      await this.handleShareUnpublish()
      await this.handleShareLeave()
    },

    // 显示成功的 Log
    addSuccessLog(log) {
      if (!this.isHostMode) {
        return
      }
      this.logList.push({
        type: 'success',
        log,
      })
      // const { scrollHeight } = this.$refs.logContainer;
      // this.$refs.logContainer.scrollTop = scrollHeight;
    },

    // 显示失败的 Log
    addFailedLog(log) {
      if (!this.isHostMode) {
        return
      }
      this.logList.push({
        type: 'failed',
        log,
      })
      // const { scrollHeight } = this.$refs.logContainer;
      // this.$refs.logContainer.scrollTop = scrollHeight;
    },
    reportSuccessEvent(name) {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      })
    },
    reportFailedEvent(name, error, type = 'rtc') {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${
          type === 'share' ? this.shareUserId : this.userId
        }*${error.message}`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      })
    },
    // 开启录制
    openMCUMix() {
      const params = {
        roomId: this.roomId,
        videoUserId: this.userId
      }
      startMCUAiMixTranscodeAPI(params).then((res) => {
        console.log('录制成功')
      })
    },
    closeMCUix() {
      const params = {
        roomId: this.roomId,
      }
      stopMCUMixTranscodeAPI(params).then((res) => {
        console.log('关闭录制成功')
      })
    },
    // 关闭录制
    /**
     * AI相关
     */

    createTalk() {
      console.error('roomID',this.roomId);
      getAICreatesessionAPI({ roomId: this.roomId }).then((res) => {
        console.log('sessionId', res)
        this.sessionId = res.data.Payload.SessionId
        this.checkTaleStatus()
      })
    },
    /**
     * 根据腾讯文档要求，通过轮询查看会话状态
     * 流状态。1：进行中，2：已关闭，3：准备中，4：建流失败
     */
    checkTaleStatus() {
      let { sessionId } = this
      let timer = setInterval(() => {
        getAIGetstateAPI({ sessionId }).then((res) => {
          console.log('查询会话状态', res.data.Payload.SessionStatus)
          if (res.data.Payload.SessionStatus != 3) {
            clearInterval(timer)
            timer = null
            if (res.data.Payload.SessionStatus == 1) {
              this.openTalk()
            } else if (res.data.Payload.SessionStatus == 4) {
              this.$message.error(
                res.data.Payload.ErrorMessage + '请联系管理员。'
              )
              console.log(res.data.Payload.ErrorMessage)
            } else {
              this.$message.error(res.data.Payload.ErrorMessage)
              console.log('会话关闭', res.data.Payload.ErrorMessage)
            }
          }
        })
      }, 500)
    },
    openTalk() {
      let { sessionId } = this
      getAIStartsessionAPI({ sessionId }).then((res) => {
        console.log('开启会话')
        // 会话开启成功之后通过websocket创建长链
        this.getWssParams()
      })
    },
    closeTalk() {
      let { sessionId } = this
      getAIClosesessionAPI({ sessionId }).then((res) => {
        console.log('关闭会话')
      })
    },
    // 智能长链
    getWssParams() {
      let { sessionId } = this
      getAISignAPI({
        sessionId,
      }).then((res) => {
        console.log('长链参数', res)
        this.wssParams = res.data
        this.createWss()
      })
    },
    createWss(isReLink = false) {
      const _this = this
      let url = this.wssUrl + this.wssParams
      this.wsk = new WebSocket(url)
      this.wsk.onopen = function () {
        console.log('WebSocket连接成功')
        // 长链建立成功之后创建ASR
        if (!isReLink) {
          _this.createASR()
        }
      }
      this.wsk.onmessage = function (event) {
        // console.log("收到消息：" + event.data);
        console.log('收到消息：', JSON.parse(event.data))
        console.log('通话状态', JSON.parse(event.data).Payload.SpeakStatus)
        let data = JSON.parse(event.data)
        // if(data.Payload.SpeakStatus == 'TextOver') {
        //   this.wskCanSendMsg = true
        // }
        if (data.Payload.SpeakStatus == 'TextOver') {
          // _this.wskCanSendMsg = true;
          if (_this.executeAction === '10') {
            _this.handleLeave()
            return
          } else if (_this.executeAction === '30') {
            _this.handleLeave()
            return
          } else {
            _this.wskCanSendMsg = true
          }
        } else if (data.Payload.SpeakStatus == 'TextStart') {
          _this.wskCanSendMsg = _this.talkCanBreak;
        }
      }
      this.wsk.onclose = function () {
        console.log('WebSocket连接关闭')
        _this.wsk = null
      }
      this.wsk.onerror = function (error) {
        console.log('WebSocket连接发生错误')
        // 长链断开需要重新建立链接
        _this.reLinkWss()
      }
    },

    sendMsg(text = '') {
      let { sessionId } = this
      let params = {
        Header: {},
        Payload: {
          ReqId: this.uuid,
          SessionId: sessionId,
          Command: 'SEND_TEXT',
          Data: {
            Text: text || '',
            ChatCommand: 'NotUseChat', //使用对话的数智人项目建的文本驱动的流，本次驱动纯文本驱动不走对话
          },
        },
      }
      console.log('推送参数', params)
      console.log('推送消息', text)
      this.wsk.send(JSON.stringify(params))
    },
    reLinkWss() {
      this.wsk.close()
      this.createWss(true)
    },
    closeWss() {
      this.wsk.close()
      this.wsk = null
    },
    /**
     * ASR相关
     */
    // 初始化ASR
    async createASR() {
      /**
       * params详见 https://cloud.tencent.com/document/product/1093/48982#.E6.8F.A1.E6.89.8B.E9.98.B6.E6.AE.B5
       */
      const _this = this
      let ASRCONFIG = await getTRTCAsrAPI()
      const params = {
        secretKey: ASRCONFIG.data.secretKey,
        secretId: ASRCONFIG.data.asrSecretId,
        appId: Number(ASRCONFIG.data.asrAppid),
        // 实时识别接口参数
        engine_model_type: '16k_zh', // 引擎
        voice_format: 1,
        // 以下为非必填参数，可跟据业务自行修改
        hotword_id: '08003a00000000000000000000000000',
        needvad: 1,
        filter_dirty: 0,
        filter_modal: 1,
        filter_punc: 1,
        convert_num_mode: 1,
        word_info: 2,
        noise_threshold: 0.3,
        // this.localStream_.getAudioTrack() 为获取的本地流的音轨
        audioTrack: this.localStream.getAudioTrack(),
      }
      this.webAudioSpeechRecognizer = new ASR(params)
      this.webAudioSpeechRecognizer.OnRecognitionStart = (res) => {
        console.log('开始识别', res)
        this.upTalkText('', 0)
      }
      // 一句话开始
      this.webAudioSpeechRecognizer.OnSentenceBegin = (res) => {
        // console.log("一句话开始", res);
        this.isCanStop = true
      }
      // 识别变化时
      this.webAudioSpeechRecognizer.OnRecognitionResultChange = (res) => {
        // console.log("识别变化时", res);
      }
      // 一句话结束
      this.webAudioSpeechRecognizer.OnSentenceEnd = (res) => {
        console.log('wsk', this.wsk)
        console.log('一句话结束', res)
        // this.testText = res.result.voice_text_str

        if (_this.wskCanSendMsg) {
          _this.upTalkText(res.result.voice_text_str, this.aiStage)
        }
      }
      // 识别结束
      this.webAudioSpeechRecognizer.OnRecognitionComplete = (res) => {
        console.log('识别结束', res)
      }
      // 识别错误
      this.webAudioSpeechRecognizer.OnError = (res) => {
        console.log('识别失败', res)
      }
      this.webAudioSpeechRecognizer.start()
    },
    closeASR() {
      if (this.isCanStop) {
        this.webAudioSpeechRecognizer.stop()
        this.webAudioSpeechRecognizer = null
        this.isCanStop = false
      }
    },
    // 上传ASR识别出的语音文字
    upTalkText(text, stage) {
      const params = {
        text,
        nodeId: stage,
        roomId: this.roomId,
      }
      getAIAsrTextAPI(params).then((res) => {
        this.sendMsg(res.data.robotScript)
        this.talkCanBreak = res.data.allowInterruptions || false
        this.executeAction = res.data.executeAction || null
        this.aiStage = res.data.nodeId || this.aiStage
      })
    },
    getUUID() {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      }
      return S4() + '' + S4() + S4() + S4() + S4() + S4() + S4() + S4()
    },
  },
  beforeDestroy () {
    this.handleLeave();
  },
}
</script>

<style lang="scss" scoped>
.rtc-container {
  height: calc(100vh - 70px);

  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }

  .control-container {
    text-align: left;
    margin-bottom: 10px;

    div:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }

    .button:not(:first-child) {
      margin-left: 2px;
    }
  }

  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;

    .invite-input {
      margin-top: 10px;
    }

    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }

  .info-container {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;

    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;

      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }

      .log-state {
        display: inline-block;
        margin-right: 6px;
      }

      > div {
        font-size: 12px;
      }
    }

    .local-stream-container {
      // width: 480px;
      // height: 360px;
      height: 100%;
      position: relative;
      flex-shrink: 0;

      .local-stream-content {
        width: 100%;
        height: 100%;
      }

      .local-stream-control {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;

        .control {
          margin-left: 10px;
        }

        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .info-container-mobile {
    display: block;

    .log-container {
      margin-right: 0;
    }

    .local-stream-container {
      width: 100%;
      // height: 240px;
      // margin-top: 10px;
    }
  }

  .remote-container {
    width: 100%;
    height: 50%;
    // margin-top: 10px;
    display: flex;
    // flex-wrap: wrap;
    overflow: hidden;

    .remote-stream-container {
      // width: 100%;
      //height: 100vh;
      margin: 0 auto;
    }
  }
}

.leaveBtn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>

<i18n>
{
	"en": {
		"Operation": "Operation",
    "Join Room": "Join Room",
    "Publish": "Publish",
    "Unpublish": "Unpublish",
    "Leave Room": "Leave Room",
    "Start Screen Share": "Start Screen Share",
    "Stop Screen Share": "Stop Screen Share",
    "Please enter sdkAppId and secretKey": "Please enter sdkAppId and secretKey",
    "Please enter userId and roomId": "Please enter userId and roomId",
    "Please reacquire the invitation link": "Please reacquire the invitation link!"
	},
	"zh": {
		"Operation": "操作",
    "Join Room": "进入房间",
    "Publish": "发布流",
    "Unpublish": "取消发布流",
    "Leave Room": "离开房间",
    "Start Screen Share": "开始共享屏幕",
    "Stop Screen Share": "停止共享屏幕",
    "Please enter sdkAppId and secretKey": "请输入 sdkAppId 和 secretKey",
    "Please enter userId and roomId": "请输入 userId 和 roomId",
    "Please reacquire the invitation link": "请重新获取邀请链接！"
	}
}
</i18n>
